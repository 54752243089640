.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.header {
  margin-top: 0rem;
  padding: 1rem;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
/* buttons are here */
.btn-primary1,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #259dd7 !important;
  color: white !important;
}
.btn-primary2,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #13758b !important;
  color: white !important;
}
.btn-primary3,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #28ae8a !important;
  color: white !important;
}
.btn-primary4,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: transparent !important;
  color: black !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
